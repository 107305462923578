import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/common/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { FormDeactivateGuard } from './common/guards/form-deactivate.guard';
import { EmployeeComponent } from './pages/common/employees/employee/employee.component';
import { SystemComponent } from './pages/common/system/system/system.component';
import { ProductComponent } from './pages/common/products/product/product.component';
import { PromotionComponent } from './pages/common/promotions/promotion/promotion.component';
import { ReportComponent } from './pages/common/reports/report/report.component';
import { UserComponent } from './pages/common/users/user/user.component';
import { UtilComponent } from './pages/common/utils/util/util.component';
import { DepartmentEditComponent } from './pages/common/system/department/department-edit/department-edit.component';
import { DepartmentComponent } from './pages/common/system/department/department.component';
import { EmployeeCategoryEditComponent } from './pages/common/employees/employee-category/employee-category/employee-category-edit/employee-category-edit.component';
import { EmployeeCategoryComponent } from './pages/common/employees/employee-category/employee-category/employee-category.component';
import { AreaComponent } from './pages/common/system/area/area.component';
import { AreaEditComponent } from './pages/common/system/area/area-edit/area-edit/area-edit.component';
import { ShopinfoComponent } from './pages/common/system/shopinfo/shopinfo.component';
import { ShopEditComponent } from './pages/common/system/shopinfo/shop-edit/shop-edit.component';
import { SystemSettingsComponent } from './pages/common/system/system-settings/system-settings.component';
import { UomComponent } from './pages/common/products/uom/uom.component';
import { ItemCategoryEditComponent } from './pages/common/products/item-category/item-category-edit/item-category-edit.component';
import { ItemCategoryComponent } from './pages/common/products/item-category/item-category.component';
import { SaleItemEditComponent } from './pages/common/products/sale-item/sale-item-edit/sale-item-edit.component';
import { SaleItemComponent } from './pages/common/products/sale-item/sale-item.component';
import { StockCategoryEditComponent } from './pages/common/products/stock-category/stock-category/stock-category-edit/stock-category-edit.component';
import { StockCategoryComponent } from './pages/common/products/stock-category/stock-category/stock-category.component';
import { StocksEditComponent } from './pages/common/products/stocks/stocks/stocks-edit/stocks-edit.component';
import { StocksComponent } from './pages/common/products/stocks/stocks/stocks.component';
import { UomEditComponent } from './pages/common/products/uom/uom-edit/uom-edit.component';

const routes: Routes = [
  { component: LoginComponent, path: 'login'},
  { component: EmployeeComponent, path: 'employee'},
  { component: SystemComponent, path: 'system'},
  { component: ProductComponent, path: 'product'},
  { component: PromotionComponent, path: 'promotion'},
  { component: ReportComponent, path: 'report'},
  { component: UserComponent, path: 'user'},
  { component: UtilComponent, path: 'util'},
  { component: DepartmentEditComponent, path: 'department-edit/:id'},
  { component:DepartmentComponent,path: 'department'},
  { component: EmployeeCategoryEditComponent, path: 'employee-category-edit/:id'},
  {component:EmployeeCategoryComponent,path: 'employee-category'},
  {component:AreaComponent,path:'area'},
  {component:AreaEditComponent,path:'area-edit/:id'},
  {component:ShopinfoComponent,path:'shops'},
  { component: ShopEditComponent, path: 'shop-edit/:id'},
  {component:SystemSettingsComponent,path:'settings'},
  { component: UomComponent, path: 'uom'},
  { component: UomEditComponent, path: 'uom-edit/:id'},

  { component: StocksComponent, path: 'stocks',  },
  { component: StocksEditComponent, path: 'stocks-edit/:id'},
  { component: StockCategoryEditComponent, path: 'stock-category-edit/:id'},
  { component: ShopinfoComponent, path: 'shops',  },
  { component: ShopEditComponent, path: 'shop-edit/:id'},
  { component: StockCategoryComponent, path: 'stock-category'},
  { component: ItemCategoryComponent, path: 'item-category'},
  { component: ItemCategoryEditComponent, path: 'item-category-edit/:id'},
  { component: SystemSettingsComponent, path: 'system-settings',  },
  { component: SaleItemComponent, path: 'sale-items',  },
  { component: SaleItemEditComponent, path: 'sale-item-edit/:id'},
  












];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FormDeactivateGuard]
})
export class AppRoutingModule { }
