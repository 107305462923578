export class AppConstants{
    static readonly SESSION_VAR_USER= 'user';
    static readonly SESSION_VAR_TOKEN='auth_token';

    static readonly SESSION_VAR_TAGS='tags';
    static readonly SESSION_VAR_SEGMENTS='tags';
    static readonly SESSION_VAR_AREA='area';
    static readonly SESSION_VAR_DEPARTMENT='department';
    static readonly SESSION_VAR_KITCHEN='kitchen';
    static readonly SESSION_VAR_UOM='uom';
    static readonly SESSION_VAR_STOCK='stock';
    static readonly SESSION_VAR_CATEGORY='category';
    static readonly SESSION_VAR_SHOP='shops';
    static readonly SESSION_VAR_ITEMCATEGORY='itemcategory';
    static readonly SESSION_VAR_EMPLOYEECATEGORY='employeecategory';
    static readonly SESSION_VAR_ITEM='item';
    static readonly SESSION_SYSTEMPARAM='systemParam';





};
export const  SESSION_VAR_STATE='page_state';

