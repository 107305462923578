<div class="promotion-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content"> 
                <mat-list-item class="mat-list-item" routerLink="/employee-category" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/vouchers.svg">
                        <span class="master-title" style="word-wrap: break-word;">Vouchers
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/employees" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/discounts.svg">
                        <span class="master-title">Discount</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/payroll" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/auto-discount.svg">
                        <span class="master-title"> Auto Discount
                        </span>
                    </div>
                </mat-list-item>
           
           
          
         
           
         
         
            </div>
        </div>
    </div>
</div>
