import { Injectable } from '@angular/core';
import { EmployeeCategoryModel } from './model/employee-category.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EmployeeCategoryService {

  constructor(private http: HttpClient) { }

  getEmployeeList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/employeecategory/get', requestData);

  }

  getEmployeeById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/employeecategory/get/'+id, '');

  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/hq/employeecategory/export-emp-category', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

   /**
   * 
   * @param employeeInfo 
   * Calls the update API to insert/update the record
   */
   update(employeeInfo: EmployeeCategoryModel): Observable<any> { 
    if(employeeInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/hq/employeecategory/update', employeeInfo);
    } else{
      return this.http.post(environment.baseUrl+'/hq/employeecategory/add', employeeInfo);
    }
  }


    /**
   * 
   * @param employeeInfo 
   * Calls the API to delete the record
   */
    delete(employeeInfo: EmployeeCategoryModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/hq/employeecategory/delete/'+employeeInfo.id, employeeInfo);
    }

}
