import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StockCategoryModel } from '../model/stock-category.model';
import { StockCategoryService } from '../../../stock-category/stock-category.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { DepartmentService } from 'src/app/pages/common/system/department/department.service';

@Component({
  selector: 'app-stock-category-edit',
  templateUrl: './stock-category-edit.component.html',
  styleUrls: ['./stock-category-edit.component.scss']
})
export class StockCategoryEditComponent {
  title: string = "New Stock Category";
  editForm: FormGroup;
  stockCategoryInfo: StockCategoryModel = {};
  isFormDirty: boolean = false;
  stock_item_category_id: any;
  selectedCategory: any;
  department_id: any;
  super_category_id: any;
  isSubCategory: boolean = false;


  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private departmentService: DepartmentService,
    private categoryService:StockCategoryService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let categoryId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.stockCategoryInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.stockCategoryInfo.name, [Validators.required, Validators.maxLength(50)]),
      department_id: this.builder.control(this.stockCategoryInfo.department_id),
      super_category_id: this.builder.control(this.stockCategoryInfo.super_category_id),



      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (categoryId !== null && parseInt(categoryId) !== 0) {
      this.setstockCategoryInfo(categoryId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating(); 
  this.loadDepartmentList();   
  this.loadCategoryList();
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  toggleCategoryDropdown() {
    this.isSubCategory = !this.isSubCategory;
    this.isFormDirty = true;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/category']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param areaId 
   */
  setstockCategoryInfo(categoryId: any): void {

    let response = this.categoryService.getById(categoryId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.stockCategoryInfo = response.data.category;
          this.editForm.patchValue(this.stockCategoryInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.stockCategoryInfo.id == 0) ? "New Stock Category Item" : "Editing : " + this.stockCategoryInfo.name;
    return title;
  }
  loadDepartmentList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 100, offset: 0 },
        },
        sort: [{ column: "code", order: "asc" }],
      },
    };
  
    this.departmentService.getDepartmentList(requestData).subscribe(
      (response: {
        status: string;
        data: {
          departments: any;
          code: any[];
          base_uom_id: any[];
        };
        message: any;
      }) => {
        if (response.status === "SUCCESS") {
          this.department_id = response.data.departments;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    );
  }
  // loadCategoryList(): void {
  //   let requestData = {
  //     search: {
  //       filters: {
  //         column: [],
  //         scope: { limit: 100, offset: 0 },
  //       },
  //       sort: [{ column: "name", order: "asc" }],
  //     },
  //   };
  
  //   this.stockService.getList(requestData).subscribe(
  //     (response: {
  //       status: string;
  //       data: {
  //        category:any;
        
  //       };
  //       message: any;
  //     }) => {
  //       if (response.status === "SUCCESS") {
  //         this.stock_item_category_id = response.data.category;
  //       } else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     }
  //   );
  // }
  loadCategoryList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 100, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.categoryService.getParentCategory(requestData).subscribe(
      (response: {
        status: string;
        data: {
          category: any;
        
        };
        message: any;
      }) => {
        if (response.status === "SUCCESS") {
          this.super_category_id = response.data.category;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    );
  }
  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.stockCategoryInfo, this.editForm.value);
      this.categoryService.update(this.stockCategoryInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.stockCategoryInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['/stocks']);

          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }

}
