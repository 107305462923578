import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/common/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { AreaComponent } from '../../area.component';
import { AreaModel } from '../../model/area.model';
@Component({
  selector: 'app-area-summary',
  templateUrl: './area-summary.component.html',
  styleUrls: ['./area-summary.component.scss']
})
export class AreaSummaryComponent {

  @Input() area: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  areas: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionArea = sessionStorage.getItem(AppConstants.SESSION_VAR_AREA);
    this.areas = (sessionArea !== null) ? JSON.parse(sessionArea) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['area']) {
    //   this.show = (this.area !== undefined);

    // }
  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.area);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.area);
  }

}
