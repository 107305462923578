<div class="edit-page-root-container system-settings-edit-root-container">
  <div class="page-content">
    <div class="page-header">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
      <div class="title">
        System Settings
      </div>
      <div class="action-btn-container">
        <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
        <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
      </div>
    </div>
    <div class="page-details">
      <div class="detail-section general-settings">
        <div class="content-container">
          <mat-tab-group animationDuration="0ms" style="width:100%;">
            <mat-tab label="SYSTEM">
              <div class="tab-title">Regional Settings</div>
              <div class="system-content">
                <mat-form-field class="date-input">
                  <input matInput placeholder="DD-MM-YYYY" value="" readonly>
                </mat-form-field>
                <mat-form-field class="date-input">
                  <input matInput placeholder="HH:MM:SS" value="" readonly>
                </mat-form-field>
                <mat-form-field class="date-input">
                  <input matInput placeholder="2" value="" readonly>
                </mat-form-field>
              </div>
              <div>
                <div class="tab-title" style="display:flex; margin-top: 20px; font-size:15px; font-weight: bold;">
                    Payment Settings
                </div>
                <table>
                  <tr>
                    <th></th>
                    <th>Payment Mode</th>
                    <th>Refundable</th>
                    <th>Refund Mode</th>
                  </tr>
                  <tr>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.cash.can_pay_by_cash"></mat-checkbox>
                    </td>
                    <td>{{settingInfo.system.refund_settings.cash.title}}</td>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.cash.can_cash_refundable"></mat-checkbox>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.company.can_pay_by_company"></mat-checkbox>
                    </td>
                    <td>{{settingInfo.system.refund_settings.company.title}}</td>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.company.can_company_refundable"></mat-checkbox>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.voucher.can_pay_by_vouchers"></mat-checkbox>
                    </td>
                    <td>{{settingInfo.system.refund_settings.voucher.title}}</td>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.voucher.can_voucher_refundable"></mat-checkbox>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.card.can_pay_by_card"></mat-checkbox>
                    </td>
                    <td>{{settingInfo.system.refund_settings.card.title}}</td>
                    <td>
                      <mat-checkbox [(ngModel)]="settingInfo.system.refund_settings.card.can_card_refundable"></mat-checkbox>
                    </td>
                    <td>
                     
                    </td>
                  </tr>
                </table>
                
                
            </div>
            
            </mat-tab>

            <mat-tab label="BILL">
              <div class="checkbox-container" style="margin-top: 20px;">
                <mat-checkbox [(ngModel)]="settingInfo.bill.show_item_discount" [disabled]="true">Show Item Discount</mat-checkbox>
              </div>
              <div class="checkbox-container">
                <mat-checkbox [(ngModel)]="settingInfo.bill.show_discount_summary" [disabled]="true">Show Discount Summary</mat-checkbox>
              </div>
              <div class="checkbox-container">
                <mat-checkbox [(ngModel)]="settingInfo.bill.show_tax_summary" [disabled]="true">Show Tax Summary</mat-checkbox>
              </div>
              <div class="tab-title">Rounding Method</div>
              <div class="bill-input" style="display:flex; gap:10px; margin-top:20px;">
                <mat-form-field class="example-full-width" style="width:20%;">
                  <mat-label>Code</mat-label>
                  <input matInput  [(ngModel)]="settingInfo.bill.rounding.code" maxlength="{{ maxNameLength }}"  required>
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:80%">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" [(ngModel)]="settingInfo.bill.rounding.name" required>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field style="width: 20%;">
                  <mat-label>Round To</mat-label>
                  <mat-select [(ngModel)]="settingInfo.bill.rounding.round_to" required>
                    <mat-option *ngFor="let option of decimalPlaceOptions" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                
              </div>
              <div style="display:flex; width:100%; gap:10px;">
                <div class="tab-title" style="width:50%">Bill Headers</div>
                <div class="tab-title" style="width:50%">Bill Footers</div>
              </div>
              <div class="bill-input" style="display:flex; gap:10px; margin-top:20px; width:100%">
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Header 1</mat-label>
                  <input matInput [(ngModel)]="settingInfo.bill.bill_header['bill_params.bill_hdr1']">
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Footer 1</mat-label>
                  <input matInput [(ngModel)]="settingInfo.bill.bill_footer['bill_footer1']">
                </mat-form-field>
              </div>
              <div class="bill-input" style="display:flex; gap:10px; width:100%">
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Header 2</mat-label>
                  <input matInput [(ngModel)]="settingInfo.bill.bill_header['bill_params.bill_hdr2']">
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Footer 2</mat-label>

                  <input matInput  [(ngModel)]="settingInfo.bill.bill_footer['bill_footer2']">
                </mat-form-field>
              </div>
              <div class="bill-input" style="display:flex; gap:10px;width:100%">
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Header 3</mat-label>
                  <input matInput  [(ngModel)]="settingInfo.bill.bill_header['bill_params.bill_hdr2']">
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Footer 3</mat-label>
                  <input matInput [(ngModel)]="settingInfo.bill.bill_footer['bill_footer2']">
                </mat-form-field>
              </div>
              <div class="bill-input" style="display:flex; gap:10px; width:100%">
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Header 4</mat-label>
                  <input matInput  [(ngModel)]="settingInfo.bill.bill_header['bill_params.bill_hdr3']">
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Footer 4</mat-label>
                  <input matInput placeholder="Bill Footer" [(ngModel)]="settingInfo.bill.bill_footer['bill_footer3']">
                </mat-form-field>
              </div>
              <div class="bill-input" style="display:flex; gap:10px; width:100%">
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Header 5</mat-label>
                  <input matInput  [(ngModel)]="settingInfo.bill.bill_header['bill_params.bill_hdr4']">
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:50%;">
                  <mat-label>Bill Footer 5</mat-label>

                  <input matInput [(ngModel)]="settingInfo.bill.bill_footer['bill_footer4']">
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab label="TAX">
              <div class="bill-input" style="display:flex; gap:10px; margin-top:20px; width:100%;">
                <mat-form-field class="example-full-width" style="width:30%;">
                  <mat-label>Code</mat-label>
                  <input matInput [(ngModel)]="settingInfo.tax.code" maxlength="{{ maxCodeLength }}" required>
                  <mat-error *ngIf="settingInfo.tax.code.length > maxCodeLength">
                    Maximum length is {{ maxCodeLength }} characters
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width" style="width:70%;">
                  <mat-label>Tax Name</mat-label>
                  <input matInput  [(ngModel)]="settingInfo.tax.name" maxlength="{{ maxNameLength }}" required>
                  <mat-error *ngIf="settingInfo.tax.name.length > maxNameLength">
                    Maximum length is {{ maxNameLength }} characters
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="example-full-width" style="width:30%;">
                  <mat-label>Tax Percentage</mat-label>
                  <input matInput [(ngModel)]="settingInfo.tax.tax1_percentage" 
                         type="number" maxlength="{{ maxPercentageLength }}" required>
                  <mat-error *ngIf="settingInfo.tax.tax1_percentage.toString().length > maxPercentageLength">
                    Maximum length is {{ maxPercentageLength }} characters
                  </mat-error>
                </mat-form-field>
              </div>
            </mat-tab>
            
            
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
