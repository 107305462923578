import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/common/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { UomComponent } from '../../uom.component';
import { UomModel } from '../../model/uom.model';
@Component({
  selector: 'app-uom-summary',
  templateUrl: './uom-summary.component.html',
  styleUrls: ['./uom-summary.component.scss']
})
export class UomSummaryComponent {

  @Input() uom: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  uoms: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionUom = sessionStorage.getItem(AppConstants.SESSION_VAR_UOM);
    this.uoms = (sessionUom !== null) ? JSON.parse(sessionUom) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['uom']) {
    //   this.show = (this.uom !== undefined);

    // }
  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.uom);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.uom);
  }

}
