<div class="edit-page-root-container shop-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <div class="input-group">
                            <div class="input-box-left"> <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="50"
                                [readonly]="shopInfo.id!==undefined && shopInfo.id!==0">
                            <mat-error *ngIf="editForm.controls['code'].hasError('required')">Shop code should be
                                entered</mat-error>
                            <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                10.</mat-error>
                                </mat-form-field></div>

                            <div class="input-box-right"> <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name">
                            <mat-error *ngIf="editForm.controls['name'].hasError('required')">Shop name should be
                                entered</mat-error>
                            <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                50.</mat-error>
                                </mat-form-field></div>

                        </div>


                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>

                        <div class="shop-mapping-container">
                            <mat-form-field class="shop-type-container" >
                                <mat-label>Shop Type</mat-label>
                                <mat-select formControlName="shop_type" [(ngModel)]="selected_shop_type"
                                (ngModelChange)="onShopTypeChange()">
                                <mat-option *ngFor="let shoptypes of shoptype" [value]="shoptypes.code">
                                    {{ shoptypes.code }}
                                </mat-option>
                            </mat-select>
                                </mat-form-field>
                                <div id="sushishop" *ngIf="is_display_bento_shop" class="mainshop-container">
                                    <mat-form-field class="mainshop" >
                                        <mat-label>Sushi Shop</mat-label>
    
                                        <mat-select formControlName="main_shop_id" [(ngModel)]="mainShop">
                                            <mat-option *ngFor="let mainshop of mainshop_list" [value]="mainshop.id">
                                                {{ mainshop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div id="dt-sushishop"  *ngIf="is_display_dt_shops" class="dt-container">
                                    <mat-form-field class="dt-sushi" >
                                        <mat-label>Sushi Shop</mat-label>
    
                                        <mat-select formControlName="dt_sushi_shop_id" [(ngModel)]="selectedSushiShop">
                                            <mat-option *ngFor="let sushishop of sushishop_list" [value]="sushishop.id">
                                                {{ sushishop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div id="bendoshop"  *ngIf="is_display_dt_shops" class="dt-container">
                                    <mat-form-field  class="dt-bento">
                                        <mat-label>Bento Shop</mat-label>
    
                                        <mat-select formControlName="dt_bento_shop_id" [(ngModel)]="selectedBentoShop">
                                            <mat-option *ngFor="let bentoshop of bento_shop_list" [value]="bentoshop.id">
                                                {{ bentoshop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                          </div>






<!-- 
                        <div class="input-group">
                            <div class="input-box-left" >
                                 <mat-form-field class="code" style="width: 135px;">
                                <mat-label>Shop Type</mat-label>
                                <mat-select formControlName="shop_type" [(ngModel)]="selected_shop_type"
                                (ngModelChange)="onShopTypeChange()">
                                <mat-option *ngFor="let shoptypes of shoptype" [value]="shoptypes.code">
                                    {{ shoptypes.code }}
                                </mat-option>
                            </mat-select>
                                </mat-form-field></div>

                            <div class="input-box-right">
                                <div id="sushishop" *ngIf="is_display_bento_shop">
                                    <mat-form-field>
                                        <mat-label>Sushi Shop</mat-label>
    
                                        <mat-select formControlName="main_shop_id" [(ngModel)]="mainShop">
                                            <mat-option *ngFor="let mainshop of mainshop_list" [value]="mainshop.id">
                                                {{ mainshop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div id="dt-sushishop" class="dt-shop" *ngIf="is_display_dt_shops">
                                    <mat-form-field>
                                        <mat-label>Sushi Shop</mat-label>
    
                                        <mat-select formControlName="dt_sushi_shop_id" [(ngModel)]="selectedSushiShop">
                                            <mat-option *ngFor="let sushishop of sushishop_list" [value]="sushishop.id">
                                                {{ sushishop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div id="bendoshop" class="dt-shop" *ngIf="is_display_dt_shops">
                                    <mat-form-field>
                                        <mat-label>Bento Shop</mat-label>
    
                                        <mat-select formControlName="dt_bento_shop_id" [(ngModel)]="selectedBentoShop">
                                            <mat-option *ngFor="let bentoshop of bento_shop_list" [value]="bentoshop.id">
                                                {{ bentoshop.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                            </div>

                        </div> -->






                      
                        <mat-checkbox formControlName="is_excluded_summary" [disabled]="isDisabled">Exclude From HQ
                            Summary</mat-checkbox>


                        

                        

                        


                        <mat-form-field>
                            <mat-label>Address</mat-label>

                            <textarea matInput formControlName="address"></textarea>
                        </mat-form-field>
                     
                        <div class="address-content">
                            <mat-form-field class="area-container">
                                <mat-label>Area</mat-label>
                                <mat-select formControlName="area_id">
                                    <mat-option *ngFor="let area of areaList" [value]="area.id">
                                        {{ area.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="">
                                <mat-label>Zip Code</mat-label>
                                <input matInput formControlName="zip_code">
                            </mat-form-field>

                        </div>
                        <div class="address-content">
                            <mat-form-field class="address-container">
                                <mat-label>City</mat-label>
                                <input matInput formControlName="city">
                            </mat-form-field>
                            <mat-form-field class="address-container">
                                <mat-label>State</mat-label>

                                <input matInput formControlName="state">
                            </mat-form-field>
                            <mat-form-field class="address-container" >
                                    <mat-label>Country</mat-label>

                                    <input matInput formControlName="country">
                                </mat-form-field>
                          </div>





                       
                        
                    </div>
                </div>
            </form>
            <div class="detail-section" >
                <div class="content-container">
                    <div class="sub-title">Department</div>
                    <table class="table table-bordered" style="width:100%;">
                        <thead>
                          <tr>
                           
                            <th>SL</th>
                            <th style="text-align: left;">CODE</th>
                            <th style="text-align: left;">NAME</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dept of shopInfo.departments;  let i=index;" >
                            
                            <td style="text-align: center;">{{ i+1 }}</td>
                            <td style="text-align: left;">{{ dept.code }}</td>
                            <td style="text-align: left;">{{ dept.name }}</td>
                            <td style="text-align: left;">
                                <mat-checkbox [(ngModel)]="dept.is_exist" ></mat-checkbox>
                              
                            </td>
                               
                          </tr>
                        </tbody>
                      </table>
                 
                    </div>
                    </div>
            <!-- <div class="dept-container">
                <div class="sub-title">Department</div>
                <div class="section-content order-value-discount-amount">
                    <div class="input-container">
                        <div class="input-row">
                            <div formArrayName="departments">
                                <div *ngFor="let department of departmentControls.controls; let i = index"
                                    [formGroupName]="i">
                                    <label>
                                        <input type="checkbox" [formControlName]="i" />
                                        {{ department.value.name }}
                                    </label>
                                </div>
                            </div> -->
                            <!-- <table class="table table-bordered" style="width:100%;">
                            <thead>
                              <tr>
                               
                                <th>SL</th>
                                <th style="text-align: left;">CODE</th>
                                <th style="text-align: left;">NAME</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dept of departmentControls.controls;  let i=index;" [formGroupName]="i">
                                
                                <td style="text-align: center;">{{ i+1 }}</td>
                                <td style="text-align: left;">{{ dept.value.code }}</td>
                                <td style="text-align: left;">{{ dept.value.name }}</td>
                                <td style="text-align: left;">
                                    <input type="checkbox" [formControlName]="i" />
                                    {{ dept.value.name }}
                                </td>
                                   
                              </tr>
                            </tbody>
                          </table> -->
                            <!-- <table mat-table [dataSource]="departmentList">
                            
    
                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef>CODE </th>
                                <td mat-cell *matCellDef="let element"> {{element.code}}
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> NAME </th>
                                <td mat-cell *matCellDef="let element">{{element.name}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="">
                                <th mat-header-cell *matHeaderCellDef>  </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox formControlName="dept_ids"></mat-checkbox>
                                </td>
                            </ng-container>
    
                            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row *matRowDef = "let row; columns: displayedColumns;"></tr>
    
                        </table> -->



                        <!-- </div>
                    </div>
                </div>
            </div> -->
            <!-- ............................... -->
        </div>
    </div>
</div>