<div class="summary-page-root-container sale-item-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }@else{
            <mat-icon>arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && item!==undefined){
    <div class="page-container" style="overflow-y: auto">
        <div class="page-header">
            <div class="title">{{item.code}} </div>
        </div>
        <div class="page-content">
            <div class="section ">
                <div class="section-title">
                    DESCRIPTION
                </div>
                <div class="section-content ">

                    <p>{{item.description}}</p>

                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section ">
                <div class="section-title">
                    DIRECT SALE
                </div>
                <div class="section-content ">
                    @if(item.is_oo_direct_sale_allowed === 1){

                    <div>

                        In-Shop<mat-icon style="color:green; height:19px;width:19px;">done</mat-icon>
                    </div>
                    }
                    @if(item.is_direct_sale_allowed === 1){
                    <div>
                        Online<mat-icon style="color:green;height:19px;width:19px;">done</mat-icon>
                    </div>

                    }

                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section">
                <div class="section-title">
                    STOCK ITEM
                </div>
                <div class="section-content" style="display: block;">

                    <span>Code</span>&nbsp;&nbsp;&nbsp; <span>:</span>&nbsp;&nbsp;
                    <span>{{item.stock_item_code}}</span><br />
                    <span>Name</span> &nbsp;&nbsp;<span>:</span>&nbsp;&nbsp;&nbsp;<span>{{item.stock_item_name}}</span>


                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section">
                <div class="section-title">
                    COLOR
                </div>
                <div class="section-content" style="display: block;">
                    <table>
                        <tr>
                          <td><span>Back Color</span></td>
                          <td style="text-align: right;">
                            @if(item.bg_color) {
                            <span style="background-color: {{item.bg_color}}; width: 20px; height: 20px; display: inline-block; border:1px solid grey"></span>
                            }
                            @else{
                            <span style="background-color: #fff; width: 20px; height: 20px; display: inline-block; border:1px solid  grey"></span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td><span>Back Color 2</span></td>
                          <td style="text-align: right;">
                            @if(item.bg_color2) {
                            <span style="background-color: {{item.bg_color2}}; width: 20px; height: 20px; display: inline-block; border:1px solid grey"></span>
                            }@else{
                            <span style="background-color: #fff; width: 20px; height: 20px; display: inline-block; border:1px solid  grey"></span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td><span>Fore Color</span></td>
                          <td style="text-align: right;">
                            @if(item.fg_color){
                            <span style="background-color: {{item.fg_color}}; width: 20px; height: 20px; display: inline-block; border:1px solid grey"></span>

                            }@else{
                            
                            <span style="background-color: #fff; width: 20px; height: 20px; display: inline-block; border:1px solid  grey"></span>
                            }
                          </td>
                        </tr>
                      </table>
                    



                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section">
                <div class="section-title">
                    HOT ITEM SETTINGS
                </div>
                <div class="section-content">
                    <div>
                       H1 @if(item.is_hot_item_1 === 1){<mat-icon style="color:green;height:19px;width:19px;">done</mat-icon>}
                       
                      </div>
                      <div>
                        H2 @if(item.is_hot_item_2 === 1){<mat-icon style="color:green;height:19px;width:19px;">done</mat-icon>}
                        
                    </div>
                       <div>
                        H3 @if(item.is_hot_item_3 === 1){<mat-icon style="color:green;height:19px;width:19px;">done</mat-icon>}
                        
                    </div>
                       <div>
                        DT

                       </div>


                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section created-info-container">
                <div class="section-content">
                    <div><strong>Created</strong></div>
                    <div style="display:flex;gap:20px;"><span>Admin</span><span>{{item.created_at | date:'dd MMM yyyy
                            HH:mm'}}</span></div>


                    <div><strong>Updated</strong></div>
                    <div style="display:flex;gap:20px;"><span>Admin</span><span>{{item.updated_at | date:'dd MMM yyyy
                            HH:mm'}}</span></div>

                </div>

            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
    }@else if(show && item==undefined){

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a sale item.</span>
    </div>
    }@else{

    }

</div>