<!-- <app-system-menu> </app-system-menu>

<div class="area-component-container">
        <div class="page-header">
                <div class="page-title">Regions</div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <button mat-button color="accent" class="btn-export">
                                <mat-icon>save_alt</mat-icon>Export</button>
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter #searchFilter [pagingData]="pagingData" 
                [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
        </app-search-filter>
                @if(pagingData.length>0){
                @if(!isHandset){
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="areaList" (matSortChange)="sortData($event)">
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="code" mat-header-cell style="width: 80px;"
                                                *matHeaderCellDef>Code</th>
                                        <td mat-cell *matCellDef="let area" style="width: 80px;"> {{area.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="name" mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let area"> {{area.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                        <th mat-sort-header="description" mat-header-cell *matHeaderCellDef> Description
                                        </th>
                                        <td mat-cell *matCellDef="let area"> {{area.description}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>
                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                     
                                                </mat-menu>
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let area; columns: displayedColumns;"
                                        (click)="onRowSelected(area)" (dblclick)="onEdit(area)"
                                        [ngClass]=" {'row-selected' : area.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>
                }
                }@else{
                <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
                </div>
                }
        </div>

</div> -->
<app-system-menu> </app-system-menu>
<div class="area-component-container page-root-container">
        <div class="page-header">
                <div class="page-title">

                        <img class="icon" src="assets/images/menu/svg/region.svg"><span
                                class="title">Regions</span>
                </div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <button mat-button color="accent" class="btn-export" (click)="exportClicked()">
                                <mat-icon>save_alt</mat-icon>Export</button>
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"  [hidePageSize]="true">
                </app-search-filter> -->
                <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"
                        [filter]="filter" (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"
                        [hidePageSize]="true">
                </app-search-filter>
                <!-- <ng-container *ngIf="pagingData.length>0; else nodata"> -->
                <!-- @if(pagingData.length > 0){
                                @if(!isHandset){ -->
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef (click)="sortData($event)"
                                                class="item-header">#</th>
                                        <td mat-cell *matCellDef="let area; let i = index" class="item-column">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container> (matSortChange)="sortData($event)">
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="code" mat-header-cell style="width: 80px;"
                                                *matHeaderCellDef (click)="sortData($event)">Code</th>
                                        <td mat-cell *matCellDef="let area" style="width: 80px;">
                                                {{area.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="name" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Name </th>
                                        <td mat-cell *matCellDef="let area"> {{area.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                        <th mat-sort-header="description" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Description </th>
                                        <td mat-cell *matCellDef="let area"> {{area.description}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>

                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                </mat-menu>
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row"
                                        *matRowDef="let area; columns: displayedColumns;"
                                        (click)="onRowSelected(area)" (dblclick)="onEdit(area)"
                                        [ngClass]=" {'row-selected' : area.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>

                <!-- </ng-container> -->
                <!-- <ng-template #nodata> -->
                @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        <!-- </ng-template> -->
        }
</div>

</div>

