<div class="login-container">
  <mat-card class="login-card">
    <!-- <mat-card-header>
        <div class="login-header-container">
          <img class="login-header" src="assets/images/app-title-logo.png">
        </div>
      </mat-card-header> -->
    <div class="login-overlay"></div>
    <mat-card-content>
      <div class="info-container">

      </div>
      
      <div class="login-form-container">
        <div class="login-title">
          LOGIN
        </div>
        <form class="login-form" [formGroup]="loginForm" (ngSubmit)="proceedUserLogin()">

          <div class="user-cred">

            <mat-form-field class="user_type">
              <mat-label>Login To </mat-label>
              <mat-select formControlName="user_type" [(ngModel)] = "selectedUser" (ngModelChange)="onUserTypeChange()" >
                <mat-option value="HQ"> HQ </mat-option>
                <mat-option value="SHOP">SHOP</mat-option>
                <mat-option value="AREA_MANAGER">AREA MANAGER</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="" *ngIf="isShopVisible">
              <mat-label>Shop Name </mat-label>
              <mat-select formControlName="shop_id" >
                <mat-option *ngFor="let shops of ShopList" [value]=shops.id>
                  {{shops.name}}
              </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="user-name">
              <mat-label>Username</mat-label>
              <input matInput formControlName="user_id">
            </mat-form-field>
            <mat-form-field class="password">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password">
            </mat-form-field>
          </div>
          <div>
            <button mat-raised-button color="primary">Login</button>
          </div>

        </form>
      </div>
    </mat-card-content>
  </mat-card>

</div>