import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AreaModel } from '../../model/area.model';
import { AreaService } from '../../area.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
@Component({
  selector: 'app-area-edit',
  templateUrl: './area-edit.component.html',
  styleUrls: ['./area-edit.component.scss']
})
export class AreaEditComponent implements DirtyCheck{

  title: string = "New Region";
  editForm: FormGroup;
  areaInfo: AreaModel = {};
  isFormDirty: boolean = false;
  ratingList : AreaModel[]= [];
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private areaService: AreaService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let areaId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.areaInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.areaInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.areaInfo.description)
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (areaId !== null && parseInt(areaId) !== 0) {
      this.setAreaInfo(areaId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/area']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param areaId 
   */
  setAreaInfo(areaId: any): void {

    let response = this.areaService.getAreaById(areaId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.areaInfo = response.data.area;
          this.editForm.patchValue(this.areaInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.areaInfo.id == 0) ? "New Region" : "Editing : " + this.areaInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.areaInfo, this.editForm.value);
      this.areaService.update(this.areaInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.areaInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/area']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }

}
