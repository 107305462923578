<div class="edit-page-root-container department-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <div class="input-group">
                            <div class="input-box-left"> 
                                <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="50"
                                        [readonly]="departmentInfo.id!==undefined && departmentInfo.id!==0">
                                    <mat-error *ngIf="editForm.controls['code'].hasError('required')">Department code should be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                        10.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="input-box-right">  
                                <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name">
                                    <mat-error *ngIf="editForm.controls['name'].hasError('required')">Department name should be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                        50.</mat-error>
                                </mat-form-field></div>

                        </div>
                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
             

              


              

            </form>
        </div>
    </div>
</div>