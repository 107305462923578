
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ToolbarService } from 'src/app/common/components/toolbar/toolbar.service';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/common/app.constants';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
@Component({
  selector: 'app-promotion-menu',
  templateUrl: './promotion-menu.component.html',
  styleUrl: './promotion-menu.component.scss'
})

export class PromotionMenuComponent implements OnDestroy {

  @Input() show: boolean = false;


  toolbarToggleEventSub: Subscription;


  constructor(private toolbarService: ToolbarService, private stateManagerService: StateManagerService) {

    /**
     * Subscribes the toolbar events
     */
    this.toolbarToggleEventSub = this.toolbarService.menuToggleEvent.subscribe(() => {
      this.toggleOptionBar();
    });
    this.show = this.toolbarService.isVisible;
    '[]'

  }

  /**
   * un register the subscription
   */
  ngOnDestroy(): void {
    this.toolbarToggleEventSub.unsubscribe();
  }

  /**
   * Toggles the display of optioin bar
   */
  toggleOptionBar(): void {
    this.show = !this.show;
  }
  resetState() {
    // Call the resetState function
    this.stateManagerService.resetState();
  }

}

