<app-system-menu> </app-system-menu>

<div class="shop-component-container page-root-container">
    <div class="page-header">
        <div class="page-title">
                <img class="icon" src="assets/images/menu/svg/shop.svg"><span
                        class="title">Shop Information</span>
        </div>            
        <div class="page-actions">
                <button mat-button color="primary" class="btn-add" (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                <button mat-button color="accent" class="btn-export" (click)="exportClicked()"> <mat-icon>save_alt</mat-icon>Export</button>
            </div>
    </div>

    <div class="data-container ">
        <app-loader [show]="isLoading"></app-loader>

        <app-search-filter #searchFilter [pagingData]="pagingData" 
        [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
</app-search-filter>

                            <section tabindex="0" class="table-container">
                                    <table matSort mat-table [dataSource]="dataSource"
                                            (matSortChange)="sortData($event)">
                                            <ng-container matColumnDef="code">
                                                    <th mat-sort-header="code" mat-header-cell  style="width: 80px;"
                                                            *matHeaderCellDef (click)="sortData($event)">Code</th>
                                                    <td mat-cell *matCellDef="let shop"  style="width: 80px;" > {{shop.code}}
                                                    </td>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                    <th mat-sort-header="name" mat-header-cell
                                                            *matHeaderCellDef (click)="sortData($event)"> Name </th>
                                                    <td mat-cell *matCellDef="let shop"> {{shop.name}}
                                                    </td>
                                            </ng-container>
                                            <ng-container matColumnDef="area">
                                                <th mat-sort-header="area" mat-header-cell
                                                        *matHeaderCellDef (click)="sortData($event)"> Area </th>
                                                <td mat-cell *matCellDef="let shop"> {{shop.area_name}}
                                                </td>
                                        </ng-container>
                                        <ng-container matColumnDef="shop_type">
                                            <th mat-sort-header="shop_type" mat-header-cell
                                                    *matHeaderCellDef  (click)="sortData($event)"> Shop Type </th>
                                            <td mat-cell *matCellDef="let shop"> {{shop.shop_type}}
                                            </td>
                                    </ng-container>
                                    <ng-container matColumnDef="is_excluded_summary">
                                        <th mat-sort-header="is_excluded_summary" mat-header-cell
                                                *matHeaderCellDef (click)="sortData($event)"> Excl. Summary </th>
                                        <td mat-cell *matCellDef="let shop">
                                                <ng-container *ngIf="shop.is_excluded_summary === 1; else showNo">
                                                        Yes
                                                    </ng-container>
                                                    <ng-template #showNo>
                                                        
                                                    </ng-template>
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="publish_status">
                                    <th mat-sort-header="publish_status" mat-header-cell
                                            *matHeaderCellDef (click)="sortData($event)"> Published </th>
                                   
                                    <td mat-cell *matCellDef="let shop" (click)="sortData($event)">

                                        <div class="shop-active-status">
                                                <div *ngIf="shop.publish_status === 1" class="status active">
                                                    Active <span></span>
                                                </div>
                                                <div *ngIf="shop.publish_status === 2" class="status expired">
                                                    Expired
                                                </div>
                                                <div *ngIf="shop.publish_status !== 1 && shop.publish_status !== 2" class="status unpublished">
                                                    Unpublished
                                                </div>
                                            </div>
                                         </td>
                            </ng-container>

                                           
                            <ng-container matColumnDef="action" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                        <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                <mat-icon>more_vert</mat-icon>
                                        </a>
                                        <mat-menu #rowMenu>
                                                <button mat-menu-item
                                                        (click)="onDelete(rowItem)">Delete</button>
                                                <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                             
                                        </mat-menu>
                                </td>
                        </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </tr>
                                            <tr mat-row class="table-element-row"
                                                    *matRowDef="let shop; columns: displayedColumns;"
                                                    (click)="onRowSelected(shop)"
                                                    (dblclick)="onEdit(shop)"
                                                    [ngClass]=" {'row-selected' : shop.id===selectedItem?.id}">
                                            </tr>
                                    </table>
                            </section>
                    
                    @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        <!-- </ng-template> -->
        }
    </div>

</div>
<!-- <app-shop-summary [shop]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-shop-summary> -->