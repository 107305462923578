import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConstants } from 'src/app/common/app.constants';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-employee-category-summary',
  templateUrl: './employee-category-summary.component.html',
  styleUrls: ['./employee-category-summary.component.scss']
})
export class EmployeeCategorySummaryComponent {

  @Input() employee: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  employees: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionEmployee = sessionStorage.getItem(AppConstants.SESSION_VAR_EMPLOYEECATEGORY);
    this.employees = (sessionEmployee !== null) ? JSON.parse(sessionEmployee) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['area']) {
    //   this.show = (this.area !== undefined);

    // }
  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.employee);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.employee);
  }

}
