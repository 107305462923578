import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { SystemSettingsModel } from './model/system-settings.model';
import { SystemSettingsService } from './system-settings.service';

interface PaymentData {
  select: boolean;
  mode: string;
  refundable: boolean;
  refundMode: string;
}

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent {
  maxCodeLength = 5;
  maxNameLength = 50;
  maxPercentageLength = 20;
  editForm!                                                                                                                                                                                                     : FormGroup;

  settingInfo: SystemSettingsModel = {
    system: {
      regional_settings: {
        date_format: 0,
        time_format: 0,
        decimal_places: 0
      },
      refund_settings: {
        cash: {
          can_pay_by_cash: 0,
          title: '',
          can_cash_refundable: 0
        },
        company: {
          can_pay_by_company: 0,
          title: '',
          can_company_refundable: 0
        },
        voucher: {
          can_pay_by_vouchers: 0,
          title: '',
          can_voucher_refundable: 0
        },
        card: {
          can_pay_by_card: 0,
          title: '',
          can_card_refundable: 0
        }
      }
    },
    bill: {
      show_item_discount: 0,
      show_discount_summary: 0,
      show_tax_summary: 0,
      rounding: {
        code: '',
        name: '',
        round_to: 0
      },
      bill_header: {},
      bill_footer: {},
      payment_mode: 0
    },
    tax: {
      code: '',
      name: '',
      tax1_percentage: 0
    }
  };

  // array for round_to

  decimalPlaceOptions = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

  // Checkbox properties
  showItemDiscount: boolean = false;
  showDiscountSummary: boolean = true;
  showTaxSummary: boolean = true;
  dataList: any;
  decimalValues: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private systemSettingService: SystemSettingsService,

  ) {

  }
  ngOnInit(): void {
    this.loadData();

  }
  navBack(): void {
    this.router.navigate(['/home']);

  }

  /**
   * UPDATE System Data
   * @returns 
   */

  // save(): void {


  //   this.systemSettingService.update(this.settingInfo).subscribe({

  //     next: (response) => {
  //       console.log('Response received:', this.settingInfo);

  //       if (response.status == "SUCCESS") {
  //         console.log(response);
  //         let snackBarData: SnackBarData = {
  //           type: "success",
  //           title: "Saved",
  //           message:
  //             "The record for system settings updated successfully"

  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       } else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     },
  //     error: (_error) => {
  //       let snackBarData: SnackBarData = {
  //         message: "Failed to update. Connection to the server failed",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //     },
  //   });

  // }
  save(): void {
    if (this.areInputFieldsEmpty()) {
      let snackBarData: SnackBarData = {
        message: "Please fill all required fields",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
  
    this.systemSettingService.update(this.settingInfo).subscribe({
      next: (response) => {
        console.log('Response received:', this.settingInfo);
  
        if (response.status == "SUCCESS") {
          console.log(response);
          let snackBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message:
              "The record for system settings updated successfully"
          };
          this.snackBarService.openSnackBar(snackBarData);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  areInputFieldsEmpty(): boolean {
    // Check whether your required input fields are empty
    return (
      !this.settingInfo.tax.code.trim() || !this.settingInfo.tax.name.trim() || !this.settingInfo.bill.rounding.code.trim()|| !this.settingInfo.bill.rounding.name.trim()||
      // Add conditions for other input fields...
      false // placeholder condition, replace with actual conditions
    );
  }
  
  

  /**
   * GET System Data
   * @returns 
   */

  loadData(): void {
    let response = this.systemSettingService.getList().subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.settingInfo = response.data.system_data;
      }
    });
  }

}