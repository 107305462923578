<div class="summary-page-root-container uom-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }@else{
            <mat-icon>arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && uom!==undefined){
    <div class="page-container">
        <div class="page-header">
            <div class="title">{{uom.code}} </div>
        </div>
        <div class="page-content">
            <div class="section ">
                <div class="section-title">
                    DESCRIPTION
                </div>
                <div class="section-content ">

                    <p>{{uom.description}}</p>

                </div>
                <div class="section-footer"></div>
            </div>

            <div class="section created-info-container">
                <div class="section-content">
                        @if(uom.created_by!=''){
                            <strong>Created</strong>
                            <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{uom.created_at | date:'dd MMM yyyy HH:mm'}}</span></div>
                        }
                        @if(uom.updated_by!=''){
                            <strong>Updated</strong>
                            <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{uom.updated_by | date:'dd MMM yyyy HH:mm'}}</span></div>
                        }
                </div>
                <div class="section-footer"></div>

            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
    }@else if(show && uom==undefined){

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a Uom.</span>
    </div>
    }@else{

    }

</div>