<div class="summary-page-root-container categorys-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }@else{
            <mat-icon>arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && category!==undefined){
    <div class="page-container">
        <div class="page-header">
            <div class="title">{{category.code}} </div>
        </div>
        <div class="page-content">
            <div class="section ">
                <div class="section-title">
                    DESCRIPTION
                </div>
                <div class="section-content">

                    <p>{{category.description}}</p>

                </div>
                <div class="section-footer"></div>
            </div>
        
            <div class="page-content">
                <div class="section">
                    <div class="section-title">
                        SUB CATEGORIES                   
                    </div>
                <div class="section-content">
                    <div *ngIf="category.sub_category_count !== 0 else noItems" style="display:inline-flex">
                      <span> Number of sub categories:</span> 
                        <!-- Check if there are sub categories -->
                        <a href="#" >
                             ({{ category.sub_category_count }})
                        </a>
                    </div>
                

   

                    <ng-template #noItems>
                        <div *ngIf="category.active_items_count !== 0 || category.inactive_items_count !== 0;">
                          
                            Active Sale Items: <a href="#">{{ category.active_items_count }}</a>
                            <br>
                            Inactive/deleted Items: <a href="#">{{ category.inactive_items_count }}</a>

                        </div>
               
                    </ng-template>

                </div>
            </div>
                <div class="section created-info-container">
                    <div class="section-content">
                        <div><strong>Created</strong></div>
                        <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{category.created_at | date:'dd MMM yyyy HH:mm'}}</span></div>                 
                        <div><strong>Updated</strong></div>
                        <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{category.updated_at | date:'dd MMM yyyy HH:mm'}}</span></div>
                  
            </div>
                </div>
            </div>
            <div class="page-footer">
                <button mat-button color="primary" class="btn-edit"
                    (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
                <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                    <mat-icon>delete</mat-icon>Delete</button>
            </div>
        </div>

      

    </div>
}@else if(show && category==undefined){
    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a categorys.</span>
    </div>
}@else{}