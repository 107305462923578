<app-product-menu> </app-product-menu>

<div class="sale-item-component-container page-root-container">
    <div class="page-header">
        <div class="page-title">
            <img class="icon" src="assets/images/menu/svg/items.svg"><span class="title"> Sale Items</span>

        </div>
        <div class="page-actions">
            <button mat-button color="primary" class="btn-add"
                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
                [disabled]="IsDownloading">
                <img class="download-icon"
                        [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
                Export
          </button>
        </div>
    </div>

    <div class="data-container ">
        <app-loader [show]="isLoading"></app-loader>
        <app-search-filter #searchFilter [pagingData]="pagingData" 
        [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
</app-search-filter>

        <section tabindex="0" class="table-container">
            <table matSort mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef (click)="sortData($event)"
                            class="item-header">#</th>
                    <td mat-cell *matCellDef="let employees; let i = index" class="item-column">{{
                            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
            </ng-container>                 
            <ng-container matColumnDef="code">
                    <th mat-sort-header="sale_items.code" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">Code</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;"> {{item.code}}</td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-sort-header="sale_items.name" mat-header-cell style="width: 100px;" *matHeaderCellDef (click)="sortData($event)">Name</th>
                    <td mat-cell *matCellDef="let item" style="width: 100px;"> {{item.name}}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-sort-header="sale_items.type" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">Type</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;">
                         @if(item.item_type === 1){
                            <a href="#"><mat-icon>search</mat-icon> </a>                        
                        }@else if(item.item_type === 2){
                            Customizable
                         }
                         @else if(item.item_type === 3){
                            Combo Item
                         }@else{

                         }

                        </td>
                </ng-container>
                <ng-container matColumnDef="short_name">
                    <th mat-sort-header="sale_items.short_name" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">ShortName</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;"> {{item.short_name}}</td>
                </ng-container>
                <ng-container matColumnDef="group">
                    <th mat-sort-header="group_item.name" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">Group</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;"> {{item.group_item_name}}</td>
                </ng-container>
                <ng-container matColumnDef="class">
                    <th mat-sort-header="item_classes.name" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">Class</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;"> {{item.sub_class_name}}</td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-sort-header="sale_items.fixed_price" mat-header-cell style="width: 80px;" *matHeaderCellDef (click)="sortData($event)">Price</th>
                    <td mat-cell *matCellDef="let item" style="width: 80px;text-align:right;"> {{item.fixed_price | currency:'USD':'symbol':'1.2-2'}}</td>
                </ng-container>
              
                
                <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                        <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                            (click)="onRowSelected(rowItem);$event.stopPropagation();">
                            <mat-icon>more_vert</mat-icon>
                        </a>
                        <mat-menu #rowMenu>
                            <button mat-menu-item (click)="onDelete(rowItem)">Delete</button>
                            <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>

                        </mat-menu>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                </tr>
                
                <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns;"
                (click)="onRowSelected(item)" (dblclick)="onEdit(item)"
                [ngClass]="{'row-selected': item.id === selectedItem?.id}">
            
            </table>
        </section>
    
        @if(pagingData.length<=0){ <div class="no-data-container">
            <img src="assets/images/no-data.png">
            <span class="no-data-info">No data found. Please remove or redefine the filters if
                    any.</span>
</div>
<!-- </ng-template> -->
}
        
    </div>

</div>
<!-- <app-sale-item-summary [item]="selectedItem" (onEdit)="onEdit($event)"
    (onDelete)="onDelete($event)"></app-sale-item-summary> -->