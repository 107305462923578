import { Injectable } from '@angular/core';
import { DepartmentModel } from './model/department.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private http: HttpClient) { }

  getDepartmentList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'hq/department/get', requestData);

  }

  getDepartmentById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/department/get/'+id, '');

  }

   /**
   * 
   * @param departmentInfo 
   * Calls the update API to insert/update the record
   */
   update(departmentInfo: DepartmentModel): Observable<any> { 
    if(departmentInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/hq/department/update', departmentInfo);
    } else{
      return this.http.post(environment.baseUrl+'/hq/department/add', departmentInfo);
    }
  }


    /**
   * 
   * @param departmentInfo 
   * Calls the API to delete the record
   */
    delete(departmentInfo: DepartmentModel): Observable<any>  {
      alert();
      return this.http.post(environment.baseUrl+'/hq/department/delete/'+departmentInfo.id, departmentInfo);
    }

    downloadExcel(requestData: any): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
  
      return this.http.post(environment.baseUrl + '/hq/department/export-department-list', requestData, { headers, responseType: 'blob' })
        .pipe(
          //catchError(this.handleError)
        );
  
    }

}
