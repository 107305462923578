<div class="summary-page-root-container shop-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <mat-icon>arrow_forward</mat-icon>
            }@else{
            <mat-icon>arrow_back</mat-icon>
            }
        </button>
    </div>
    @if(show && shop!==undefined){
    <div class="page-container" style="overflow-y: auto">
        <div class="page-header">
            <div class="title">{{shop.code}} : {{shop.name}} </div>
        </div>
        <div class="page-content">
            <div class="section ">
                <div class="section-title">
                    DESCRIPTION
                </div>
                <div class="section-content ">

                    <p>{{shop.description}}</p>

                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section ">
                <div class="section-title">
                    ADDRESS
                </div>
                <div class="section-content ">

                    <p>{{shop.address}}</p>

                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section ">
                <div class="section-title">
                    DEPARTMENT
                </div>
                <div class="section-content ">

                    <table mat-table [dataSource]="shop.dept">
                        <!-- <ng-container matColumnDef="from">
                            <th mat-header-cell style="width: 80px;" *matHeaderCellDef>FROM</th>
                            <td mat-cell *matCellDef="let settings" style="width: 80px;"> {{settings.from}}
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef>CODE </th>
                            <td mat-cell *matCellDef="let element"> {{element.code}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> NAME </th>
                            <td mat-cell *matCellDef="let element">{{element.name}}
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>

                </div>
                <div class="section-footer"></div>
            </div>


            <div class="section created-info-container">
                <div class="section-content">
                    <div><strong>Created</strong></div>
                    <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{shop.created_at | date:'dd MMM yyyy HH:mm'}}</span></div>
               
               
                    <div><strong>Updated</strong></div>
                    <div style="display:flex;gap:20px;" ><span>Admin</span><span>{{shop.updated_at | date:'dd MMM yyyy HH:mm'}}</span></div>
              
        </div>
            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
    }@else if(show && shop==undefined){

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a shop.</span>
    </div>
    }@else{}

</div>