import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common/app.constants';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userId: string = "";
  password: string = "";
  selectedUser:string ="HQ";
  ShopList: any;
  isShopVisible:boolean;

  constructor(private builder: FormBuilder,
    private router: Router, private loginService: LoginService,
    private snackBarService: SnackBarService
  ) {
    this.isShopVisible=false;
    sessionStorage.clear();
    this.loginForm = this.builder.group({
      user_id: this.builder.control('', Validators.required),
      password: this.builder.control('', Validators.required),
      user_type: this.builder.control('', Validators.required),
      shop_id: this.builder.control('')
    });

  }

  ngOnInit(): void { 
    this.authenticate();
  }

   /**
  * list active shops
  * 
  */
   getShopList() {
    this.loginService.getShopList().subscribe(
      (resp: any) => {
        if (resp.status == 'SUCCESS') {
          this.ShopList = resp.data.shops;
        } else {
         // this.snackBarService.error(resp.message);
          let snackBarData: SnackBarData = {
            title: "Failed",
            message: resp.message,
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
      

    );

  }

  /**
   * Validate the application credentials and navigate to user login
   */
  authenticate() {
    
      let authData = {
        user_name: environment.userId,
        password: environment.password,
        apikey: environment.appKey
      };

      this.loginService.authenticate(authData).subscribe(
        response => {
          if (response.status == 'SUCCESS') {
            let token = response.token;
            sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
            this.getShopList();
          } else {
            let snackBarData: SnackBarData = {
              title: "Failed",
              message: response.message,
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        }
      );
    
  }

  /**
   * Validate the user credentials and navigate to dashboard
   */
  proceedUserLogin() {
    let loginData ={};
    //console.log(this.loginForm);
    // if (this.selectedUser != 'SHOP') {
    //   this.loginForm.get('shop_id').clearValidators();
    //   this.loginForm.get('shop_id').updateValueAndValidity();
    // } else {
    //   this.loginForm.get('shop_id').setValidators(Validators.required);
    //   this.loginForm.get('shop_id').setValidators(Validators.required);
    // }
    if (this.loginForm.valid) {
      if (this.selectedUser != 'SHOP') {
       loginData = {
        user_name: this.loginForm.get("user_id")?.value,
        password: this.loginForm.get("password")?.value,
        user_type: this.loginForm.get("user_type")?.value,
        shop_id: 0
      };
    }else{
       loginData = {
        user_name: this.loginForm.get("user_id")?.value,
        password: this.loginForm.get("password")?.value,
        user_type: this.loginForm.get("user_type")?.value,
        shop_id: this.loginForm.get("shop_id")?.value
      };
    }
      console.log(loginData);
       
      this.loginService.userLogin(loginData).subscribe(
        response => {
          if (response.status == 'SUCCESS') {
            let curUser = response.data;
            let token = response.token;
            sessionStorage.setItem(AppConstants.SESSION_VAR_USER, JSON.stringify(curUser));
            sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
            this.router.navigate(['home']);
          } else {
            let snackBarData: SnackBarData = {
              title: "Login Failed.",
              message: response.message,
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        }
      );
    }
  }

 

  onUserTypeChange() {
    let userType = this.selectedUser;
    if(userType =='SHOP'){
     this.isShopVisible=true;
    }else{
      this.isShopVisible=false;
    }
    
  }
}
