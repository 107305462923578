import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UomModel } from '../model/uom.model';
import { UomService } from '../uom.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
@Component({
  selector: 'app-uom-edit',
  templateUrl: './uom-edit.component.html',
  styleUrls: ['./uom-edit.component.scss']
})
export class UomEditComponent implements DirtyCheck{

  title: string = "New Uom";
  editForm: FormGroup;
  uomInfo: UomModel = {};
  isFormDirty: boolean = false;
  numbers: number[] = this.generateNumbers(1, 100);
  selectedValue: number | undefined;
  selectedBaseUom: any;
  base_uom_id: any;
 // uomList: string[] = []; // Assuming `baseuomList` will hold the list of UOMs

  generateNumbers(start: number, end: number): number[] {
    return Array.from({length: end - start + 1}, (_, i) => i + start);
  }
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private uomService: UomService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let uomId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.uomInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.uomInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.uomInfo.description, [Validators.required, Validators.maxLength(50)]),
      decimal_places: this.builder.control(this.uomInfo.decimal_places),
      uom_symbol: this.builder.control(this.uomInfo.uom_symbol,[]),
      compound_unit: this.builder.control(this.uomInfo.compound_unit,[]),
      //base_uom_id: [this.uomInfo.selectedBaseUom], // Add this line
      base_uom_id: this.builder.control(this.uomInfo.base_uom_id), // Add this line



      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (uomId !== null && parseInt(uomId) !== 0) {
      this.setuomInfo(uomId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();   
  this.loadUomList();
 
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/uom']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param uomId 
   */
  setuomInfo(uomId: any): void {

    let response = this.uomService.getUomById(uomId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.uomInfo = response.data.uom;
         this.selectedBaseUom = this.uomInfo.selectedBaseUom;

          this.editForm.patchValue(this.uomInfo);
      

          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.uomInfo.id == 0) ? "New Uom" : "Editing : " + this.uomInfo.name;
    return title;
  }
  /**
   * Loads the baseUom
   */
  // loadUomList(): void {

  //   let requestData = {
  //     search: {
  //       filters: {
  //         column: [],
  //         scope: { limit: 100, offset: 0 },
  //       },
  //       sort: [{ column: "name", order: "asc" }],
  //     },
  //   };

  //   this.uomService.getBaseUomList(requestData).subscribe({
  //     next: (response) => {
  //       if (response.status === "SUCCESS") {
  //         this.uomList = response.data.uoms;

  //         // Fetch products from the API
  //       } else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     },
  //     error: (error) => {
  //       let snackBarData: SnackBarData = {
  //         message: "Failed to load item Base Uoms.",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //   });
  // }
  loadUomList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 100, offset: 0 },
        },
        sort: [{ column: "code", order: "asc" }],
      },
    };

    this.uomService.getBaseUomList(requestData).subscribe(
      (response: {
        status: string;
        data: {
          uoms: any;
          code: any[];
          name:any;
        };
        message: any;
      }) => {
        if (response.status === "SUCCESS") {
         // this.selectedBaseUom = response.data.uoms;
         this.base_uom_id= response.data.uoms;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    );
  }
  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.uomInfo, this.editForm.value);
      this.uomService.update(this.uomInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.uomInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/uom']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }

}
