export const environment = {
  production: false,
  buildNo:202412112243,
  ver:'DEV 2.0.1',
  assetsUrl: 'https://dev.admin.oov2.mojosoft.work/resources/',
  baseUrl: 'https://dev.csmsv2-api.mojosoft.work',
  userId: 'crm',
  password: '1234',
  appKey:"e568458e9121478125d076b344e26c9e",
  crm:{
    apiUrl:'https://dev.crm.mojosoft.work/ws',
    userId: 'oos',
    password: '1234',
    appKey: "e568458e9121478125d076b344e26c9e",
  }
};
