<mat-toolbar class="main-toolbar">
    <button mat-button class="toggle-menu-container" (click)="toggle()">
        <!-- <mat-icon class="sidenav-toggle-menu" >menu</mat-icon> -->
        <svg class="sidenav-toggle-menu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            id="dehaze">
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M2 16v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20V6H2z"></path>
        </svg> </button>
    <div *ngIf="!isHandset" class="app-title-logo-container">
        <img src="assets/images/logo.png" alt="">/
        
        <!-- <font style="font-size:30px; margin-left:20px" color="black">CRM</font> -->
        <!-- <font color="#fff200"> sushi</font> -->
        <span class="app-title"> EPS Admin Console</span>
        
    </div>

    <div class="title-container">
        <div *ngIf="isHandset" class="app-title-logo-container handset">
            <img src="assets/images/logo.png" alt="">
            <b>
                <font color="white">EPS</font>
                <font color="#fff200"> admin</font>
                <font color="orange"> client</font>
            </b>
        </div>
    </div>
    <!-- <div class="extra-modules-menu-container">
        <button mat-icon-button  (click)="showExtraPageMenu=!showExtraPageMenu">
            <svg color="black" focusable="false" viewBox="0 0 24 24">
                <path
                    d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z">
                </path>
            </svg></button>
    </div> -->
    <div class="account-info">
        <button maat-button class="account_info_btn" (click)="showUserInfo=!showUserInfo">
            <div class="user-info">
                <span>{{user?.name}}</span>
            </div>
            <mat-icon>account_circle</mat-icon>
        </button>
    </div>
</mat-toolbar>
<div class="overlay-container" *ngIf="showUserInfo" (click)="showUserInfo=!showUserInfo">
    <app-user-info #userInfo class="account-info"></app-user-info>
</div>

<div class="overlay-container" *ngIf="showExtraPageMenu" (click)="showExtraPageMenu=!showExtraPageMenu">
    <app-extra-pages-menu ></app-extra-pages-menu>
</div>
