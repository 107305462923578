import { Injectable } from '@angular/core';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SystemSettingsModel } from './model/system-settings.model';
@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient) { }
  getList(): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getSystemData', "");
  }

  getTaxList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getTax', requestData);

  }

  getRoundingList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getRounding', requestData);

  }

  getTaxparamList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getTaxparam', requestData);

  }

  getBillparamList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getBillparam', requestData);

  }
  getPaymentList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/getPayment', requestData);

  }
  update(settingInfo: SystemSettingsModel): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/systemsettings/update', settingInfo);
  }

}





