import { Component, ViewEncapsulation } from '@angular/core';
import { StocksModel } from './models/stocks.model';
import { StocksService } from './stocks.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { UomService } from '../../uom/uom.service';
@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent {

  stockList!: any[];
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "stock_items.code",
    },
    {
      title: "Name",
      column: "stock_items.name",
    },
    {
      title: "Category",
      column: "stock_item_categories.name",
    },
    {
      title: "UOM",
      column: "stock_items.uom_id",
    },
    {
      title: "Sellable",
      column: "is_sellable",
    },
    {
      title: "Manufactured",
      column: "is_manufactured",
    },

  ];
  displayedColumns = ['code', 'name', 'stock_item_categories.name', 'uom_code', 'is_sellable', 'is_manufactured', 'action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };
  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: StocksModel;
  showSummaryPanel: boolean = false;
  selection = new SelectionModel<StocksModel>(true, []);
  constructor(private breakpointObserver: BreakpointObserver,
    private StockService: StocksService,

    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router) {

    this.stockList = [];
  }
  /**
    * initilaize the device model
    */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    // this.loadUomList();
    this.loadData();
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

  /**
     * Builds the request data to be send to API
     * @returns Request Data
     */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
    * Set the column filter and reaload the daaa
    * @param columFilter 
    */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
    * On delete menu item selected
    * Confirm the action and call the api to update
    */
  onDelete(stockInfo: StocksModel): void {
    const stockId = stockInfo.id;
    const message = `Are you sure, you want to delete ` + (stockInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.StockService.delete(stockInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + stockInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   * loads the data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    let response = this.StockService.getStocksList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.stockList = response.data.stocks;
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.stockList[0])
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }


  /**
  * On areainfo selected
  * @param areaInfo 
  */
  onRowSelected(stockInfo: StocksModel): void {
    this.selectedItem = stockInfo;
    this.showSummaryPanel = true;
  }

  /**
    *  Set the sort param and relead the data
    * @param $event sort data
    * {"active": "email","direction": "asc"}
    *   
    */
  sortData(sd: any): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }
  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(stockInfo: StocksModel): void {

    this.router.navigate(['stocks-edit', stockInfo.id]);
  }


  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
   * On Add clicked
   * Show the dialog to enter new area
   */
  addClicked(): void {

    this.router.navigate(['stocks-edit', 0]);

  }
  exportClicked() {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
    this.StockService.downloadExcel(requestData)
      .subscribe({
        next: blob => {
          // Download logic
          this.IsDownloading = false;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'stocks.csv'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;


        },
        error: error => {
          // Handle errors
          this.IsDownloading = false;
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      }
      );
  }

}










