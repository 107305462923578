<app-product-menu> </app-product-menu>

<div class="category-component-container page-root-container">
    <div class="page-header">
            <div class="page-title">
                        
                <img class="icon" src="assets/images/menu/svg/item-category.svg"><span class="title">Stock Category Items</span>
        </div>
            
            <div class="page-actions">
                <button mat-button color="primary" class="btn-add"
                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
                [disabled]="IsDownloading">
                <img class="download-icon"
                        [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
                Export
          </button>
            </div>
    </div>

    <div class="data-container ">
        <app-loader [show]="isLoading"></app-loader>

        <app-search-filter #searchFilter [pagingData]="pagingData" 
        [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
</app-search-filter>
            @if(pagingData.length>0){
                @if(!isHandset){
                            <section tabindex="0" class="table-container">
                                    <table matSort mat-table [dataSource]="stockCategoryList"
                                            (matSortChange)="sortData($event)">
                                            <ng-container matColumnDef="code">
                                                    <th mat-sort-header="stock_item_categories.code" mat-header-cell  style="width: 80px;"
                                                            *matHeaderCellDef>Code</th>
                                                    <td mat-cell *matCellDef="let category"  style="width: 80px;"> {{category.code}}
                                                    </td>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                    <th mat-sort-header="stock_item_categories.name" mat-header-cell
                                                            *matHeaderCellDef> Name </th>
                                                    <td mat-cell *matCellDef="let category"> {{category.name}}
                                                    </td>
                                            </ng-container>
                                            <ng-container matColumnDef="department_id">
                                                <th mat-sort-header="stock_item_categories.department_id" mat-header-cell
                                                        *matHeaderCellDef> Department </th>
                                                <td mat-cell *matCellDef="let category"> {{category.department_name}}
                                                </td>
                                        </ng-container>
                                        <ng-container matColumnDef="super_category_id">
                                            <th mat-sort-header="stock_item_categories.super_category_id" mat-header-cell
                                                    *matHeaderCellDef> Super Category </th>
                                            <td mat-cell *matCellDef="let category"> {{category.super_category_name}}
                                            </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px"
                                                *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn"
                                                        [matMenuTriggerFor]="rowMenu" (click)="$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>
                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item
                                                                (click)="onEdit(rowItem)">Edit</button>
                                                </mat-menu>
                                        </td>
                                </ng-container>
                                    
                                
                                           

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                            </tr>
                                            <tr mat-row class="table-element-row"
                                                    *matRowDef="let category; columns: displayedColumns;"
                                                    (click)="onRowSelected(category)"
                                                    (dblclick)="onEdit(category)"
                                                    [ngClass]=" {'row-selected' : category.id===selectedItem?.id}">
                                            </tr>
                                    </table>
                            </section>
                }
                 }@else{
                    <div class="no-data-container">
                            <img src="assets/images/no-data.png">
                            <span class="no-data-info">No data found. Please remove or redefine the filters if
                                    any.</span>
                    </div>
                }
    </div>

</div>
<!-- <app-stock-category-summary [category]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-stock-category-summary> -->