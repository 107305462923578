import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleItemModel } from '../model/sale-item.model';
import { SaleItemService } from '../sale-item.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-sale-item-edit',
  templateUrl: './sale-item-edit.component.html',
  styleUrl: './sale-item-edit.component.scss'
})
export class SaleItemEditComponent implements DirtyCheck, OnInit {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  
  myControl = new FormControl('');
  title: string = "New Sale Item";
  editForm: FormGroup;
  saleItemInfo: SaleItemModel = {};
  isFormDirty: boolean = false;
  isMultiColorChecked: boolean = false;
  isSecondColorEditable!: boolean;
  stock_item_id: any[] = [];
  kitchen_id: any;
  item_classes_id: any;
  tax_id: any;
  selectedValue!: string;
  selectedCode!: string;
  selectedStock: any;
  filteredOptions: any[] = [];
  selectedId: any;

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private saleItemService: SaleItemService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
  ) {
    let saleItemId = this.route.snapshot.paramMap.get('id');

    this.editForm = this.builder.group({
      code: [this.saleItemInfo.code, [Validators.required, Validators.maxLength(10)]],
      name: [this.saleItemInfo.name, [Validators.required, Validators.maxLength(50)]],
      description: [this.saleItemInfo.description],
      stock_item_id: [this.saleItemInfo.stock_item_id, [Validators.required]],
      short_name: [this.saleItemInfo.short_name],
      name_to_print: [this.saleItemInfo.name_to_print],
      item_type: ['1'],
      fixed_price: [this.saleItemInfo.fixed_price, [Validators.required, Validators.maxLength(10)]],
      is_open: [this.saleItemInfo.is_open],
      item_cost: [this.saleItemInfo.item_cost],
      fg_color: [this.saleItemInfo.fg_color],
      bg_color: [this.saleItemInfo.bg_color],
      bg_color2: [this.saleItemInfo.bg_color2],
      is_require_weighing: [this.saleItemInfo.is_require_weighing],
      is_hot_item_1: [this.saleItemInfo.is_hot_item_1],
      is_hot_item_2: [this.saleItemInfo.is_hot_item_2],
      is_hot_item_3: [this.saleItemInfo.is_hot_item_3],
      is_dt_item: [this.saleItemInfo.is_dt_item],
      kitchen_id: [this.saleItemInfo.kitchen_id],
      tax_id: [this.saleItemInfo.tax_id, [Validators.required]],
      is_valid: [this.saleItemInfo.is_valid],
      selectedStock: [this.selectedStock],
    });

    if (this.saleItemInfo.stock_item_id) {
      this.editForm.get('stock_item_id')?.setValue(this.saleItemInfo.stock_item_id);
    }

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (saleItemId !== null && parseInt(saleItemId) !== 0) {
      this.setAreaInfo(saleItemId);
    }
  }

  ngOnInit() {
    this.loadStockList();
    this.loadKitchenList();
    this.loaditemClassesList();
    this.loadTaxList();

    this.myControl.valueChanges.subscribe(() => {
      this.filter();
    });
  }

  onInputBlur(): void {
    // Add any additional logic you need on blur
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedStockName = event.option.value;
    this.selectedStock = this.stock_item_id.find((stock: { name: string; code: string; id: string }) => stock.name === selectedStockName);

    if (this.selectedStock) {
      this.selectedCode = this.selectedStock.code;
      this.selectedId = this.selectedStock.id;
      this.myControl.setValue(this.selectedStock.name);
      this.editForm.get('stock_item_id')?.setValue(this.selectedStock.id);
    }
  }

  displayStockItem(stock: { name: string, id: string } | null): string {
    return stock ? stock.name : '';
  }

  trackById(index: number, stock: { id: string; }): string {
    return stock.id;
  }

  filter(): void {
    const filterValue = this.myControl.value?.toLowerCase() || '';
    this.filteredOptions = this.stock_item_id.filter((stock: any) => 
      stock.name.toLowerCase().includes(filterValue)
    );
  }

  toggleColorInput(checked: boolean): void {
    this.isMultiColorChecked = checked;
    this.isSecondColorEditable = checked;
  }

  isDirty(): boolean {
    return this.isFormDirty;
  }

  navBack(): void {
    this.router.navigate(['/sale-items']);
  }

  setAreaInfo(saleItemId: any): void {
    // Implement this method if needed
  }

  getTitle(): string {
    return (this.saleItemInfo.id == 0) ? "New Sale Item" : "Editing : " + this.saleItemInfo.name;
  }

  loadStockList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 100, offset: 0 },
        },
        sort: [{ column: "code", order: "asc" }],
      },
    };
  
    this.saleItemService.getStocksList(requestData).subscribe(
      (response: {
        data: any;
        status: string;
        message: any;
      }) => {
        if (response.status === "SUCCESS") {
          this.stock_item_id = response.data.stocks;
          this.filteredOptions = this.stock_item_id;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    );
  }

  loadKitchenList(): void {
    // Implement this method
  }

  loaditemClassesList(): void {
    // Implement this method
  }

  loadTaxList(): void {
    // Implement this method
  }

  save(): void {
    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.saleItemInfo, this.editForm.value);
      this.saleItemService.update(this.saleItemInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.saleItemInfo.name + " has been updated successfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/sale-items']);

          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    } else {
      this.editForm.markAllAsTouched();
    }
  }
}