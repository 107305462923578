import { Injectable } from '@angular/core';
import { FilterOperator } from './search-filter.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterModel } from './model/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {


  constructor(private http: HttpClient) { }

  getDefultOperators(type: string): FilterOperator[] {

    let filter: FilterOperator[] = [];
    switch (type) {
      case "number":
        filter = [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" },
          { title: "> ", operator: ">" },
          { title: "< ", operator: "<" },
        ];
        break;
      case "date":
        filter = [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" },
          { title: ">", operator: ">" },
          { title: "< ", operator: "<" },
        ];
        break;
      case "boolean":
        filter = [
          { title: "is", operator: "=" }
        ];
        break;
        case "list":
          filter = [
            { title: "in", operator: "in" },
            { title: "not in", operator: "not in" },
          ];
          break;
      default:
        filter = [
          { title: "is", operator: "=" },
          { title: "is not", operator: "!=" },
          { title: "like", operator: "like" }
        ];
        break;
    }
    return filter;
  }

/**
 * Call the API to save the filter
 * @param data data to save
 */
  saveFilter(data: any): Observable<any> {
  
    return this.http.post(environment.baseUrl+'/filter/save', data);
  }

  /**
 * Call the API to load the filter
 * @param data data to save
 */
  getFilter(data: any): Observable<any> {
  
    return this.http.post(environment.baseUrl+'/filter/get', data);
  }

   /**
   * 
   * @param tagInfo 
   * Calls the API to delete the record
   */
   delete(filterInfo: FilterModel): Observable<any>  {
    return this.http.post(environment.baseUrl+'/filter/delete/'+filterInfo.id, filterInfo);
  }


}
