<div class="edit-page-root-container sale-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        
        <!-- <mat-tab-group style="background: transparent;">
            <mat-tab label="Sale items" style="text-align: left;"> -->
        <div class="page-details">
           
            <form class="edit-form" [formGroup]="editForm">
                <mat-tab-group style="background: #fff;">
                    <mat-tab label="Sale items" >
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="input-group">
                                    <div class="input-box-right">
                                        <mat-form-field class="name">
                                            <mat-label>Stock Item Name</mat-label>
                                            <input #input
                                                   type="text"
                                                   matInput
                                                   [formControl]="myControl"
                                                   [matAutocomplete]="auto"
                                                   (blur)="onInputBlur()">
                                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                                              <mat-option *ngFor="let stock of filteredOptions; trackBy: trackById" [value]="stock.name">
                                                {{ stock.name }}
                                              </mat-option>
                                            </mat-autocomplete>
                                          </mat-form-field>                                     
                                    </div>
                                <!-- Display the selected code -->
                                    <div class="input-box-left">
                                        <mat-form-field class="code">
                                            <mat-label>Stock item code</mat-label>
                                            <input matInput maxlength="20" [value]="selectedStock?.code || ''" readonly>
                                        </mat-form-field>
                                        <!-- <mat-form-field class="code">
                                            <mat-label>Stock item code</mat-label>
                                            <input matInput maxlength="20" [value]="selectedStock?.id || ''" formControlName="stock_item_id">
                                        </mat-form-field> -->
                                        
                                    </div>

                                </div>
                                <div>
                                    <mat-form-field style="width:50%;">
                                        <mat-label>Item type</mat-label>
                                            <mat-select formControlName="item_type" [(value)]="selectedValue">

                                            <mat-option value="1">Regular item</mat-option>
                                            <mat-option value="2">Customizable item</mat-option>
                                            <mat-option value="3">Combo item</mat-option>
                                          </mat-select>
                                          
                                    </mat-form-field>


                                </div>
                                <div class="input-group">
                                    <div class="input-box-left">
                                        <mat-form-field class="code">
                                            <mat-label>Code</mat-label>
                                            <input matInput maxlength="50" formControlName="code">
                                            <mat-error *ngIf="editForm.controls['code'].hasError('required')">code should be
                                                entered</mat-error>
                                            <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                                10.</mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="input-box-right">
                                        <mat-form-field class="name">
                                            <mat-label>Name</mat-label>
                                            <input matInput formControlName="name">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <mat-form-field class="description">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput formControlName="description"></textarea>
                                </mat-form-field>

                                <mat-form-field class="block-input">
                                    <mat-label>Short name</mat-label>
                                    <input matInput formControlName="short_name">
                                </mat-form-field>
                                <mat-form-field class="block-input">
                                    <mat-label>Name to print</mat-label>
                                    <input matInput formControlName="name_to_print">
                                </mat-form-field>
                                <mat-form-field class="block-input">
                                    <mat-label>Item class</mat-label>
                                    <input type="text" aria-label="Stock Item Name" matInput
                                        formControlName="stock_item_id" [matAutocomplete]="auto">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        @for (stock of stock_item_id; track stock.id)
                                        {
                                        <mat-option [value]="stock.name">{{stock.name}}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field>


                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">Display Colors
                                </div>
                                <div class="color-container">
                                    <mat-form-field class="color-chooser fg-color">
                                        <mat-label>Foreground color</mat-label>
                                        <input matInput type="color" formControlName="fg_color">
                                    </mat-form-field>
                                    <mat-form-field class="color-chooser bg-color" formControlName="bg_color">
                                        <mat-label>Background Color-1</mat-label>
                                        <input matInput type="color">
                                    </mat-form-field>
                                    <mat-checkbox (change)="toggleColorInput($event.checked)">Use
                                        Multicolor?</mat-checkbox>
                                    @if(isMultiColorChecked) {
                                    <mat-form-field class="color-chooser bg-color">
                                        <mat-label>Background Color-3</mat-label>
                                        <input matInput type="color" formControlName="bg_color2">
                                    </mat-form-field>
                                    }
                                    @else{
                                    <mat-form-field class="color-chooser bg-color">
                                        <mat-label>Background Color-3</mat-label>
                                        <input matInput type="color" formControlName="bg_color2"
                                            [readonly]="!isSecondColorEditable">
                                    </mat-form-field>
                                    }


                                </div>

                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">Taxation, pricing, cost and UOM
                                </div>
                                <mat-form-field style="width:50%;">
                                    <mat-label>Tax</mat-label>
                                    <mat-select formControlName="tax_id">
                                        @for(tax of tax_id; track tax.id)
                                        {
                                        <mat-option [value]="tax.id">
                                            {{ tax.name}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="block-input" style="width:20%;">
                                    <mat-label>Fixed Price</mat-label>
                                    <input matInput formControlName="fixed_price" type="number">
                                </mat-form-field>
                                <mat-checkbox formControlName="is_open"> Open Price</mat-checkbox>
                                <mat-checkbox formControlName="is_require_weighing"> Require Weighing</mat-checkbox>
                                <br />
                                <mat-form-field class="block-input" style="width:20%;">
                                    <mat-label>Cost</mat-label>
                                    <input matInput formControlName="item_cost" type="number">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">This item is available for direct sale
                                </div>
                                <mat-checkbox formControlName="is_direct_sale_allowed"> Shop</mat-checkbox>
                                <mat-checkbox formControlName="is_oo_direct_sale_allowed"> Online ordering
                                    system</mat-checkbox>
                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">Kitchen
                                </div>
                                <mat-form-field style="width:50%;">
                                    <mat-label>Kitchen</mat-label>
                                    <mat-select formControlName="kitchen_id">
                                        @for (kitchen of kitchen_id; track kitchen.id)
                                        {
                                        <mat-option [value]="kitchen.id">
                                            {{ kitchen.name }}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">This item is also available under these special keys
                                </div>
                                <div style="display:flex; gap:5px;">
                                    <mat-checkbox formControlName="is_hot_item_1"> Hot Item-1</mat-checkbox>
                                    <mat-checkbox formControlName="is_hot_item_2"> Hot Item-2</mat-checkbox>
                                    <mat-checkbox formControlName="is_hot_item_3"> Hot Item-3</mat-checkbox>
                                    <mat-checkbox formControlName="is_hot_item_1"> Drive Through</mat-checkbox>

                                </div>
                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">This item belongs to the following group
                                </div>
                                <div class="grouping-section" style="display: flex; align-items: center;">
                                    <mat-form-field style="width:50%; margin-right: 10px;">
                                        <mat-label>Grouping</mat-label>
                                        <mat-select>
                                            <mat-option [value]="1">Group 1</mat-option>
                                            <mat-option [value]="2">Group 2</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <button mat-raised-button color="primary">Add</button>

                                </div>
                            </div>
                        </div>
                        <div class="detail-section" style="margin-bottom:30px;">
                            <div class="content-container">
                                <div class="sub-title">Validity
                                </div>
                                <mat-checkbox formControlName="is_valid"> Valid Item</mat-checkbox>

                            </div>
                        </div>
                   

                    </mat-tab> 
                    @if(selectedValue === '2'){
                <mat-tab label="Customization"></mat-tab>
                    }@else if(selectedValue === '3'){
                        <mat-tab label="Combo Items"></mat-tab>


                    }

                
                </mat-tab-group>
            </form>
      
        </div>
    <!-- </mat-tab> 
    @if(selectedValue === '2'){
<mat-tab label="Customization"></mat-tab>
    }@else if(selectedValue === '3'){
        <mat-tab label="Combo Items"></mat-tab>


    }


</mat-tab-group> -->

    </div>
</div>