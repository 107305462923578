import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeCategoryService } from '../employee-category.service';
import { EmployeeCategoryModel } from '../model/employee-category.model';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';

@Component({
  selector: 'app-employee-category-edit',
  templateUrl: './employee-category-edit.component.html',
  styleUrls: ['./employee-category-edit.component.scss']
})
export class EmployeeCategoryEditComponent implements DirtyCheck{
  title: string = "New Employee Category";
  editForm: FormGroup;
  employeeInfo: EmployeeCategoryModel = {};
  isFormDirty: boolean = false;
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private EmployeeCategoryService: EmployeeCategoryService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let employeeId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.employeeInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.employeeInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.employeeInfo.description)
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (employeeId !== null && parseInt(employeeId) !== 0) {
      this.setemployeeInfo(employeeId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/hq/employeecategory']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param employeeId 
   */
  setemployeeInfo(employeeId: any): void {

    let response = this.EmployeeCategoryService.getEmployeeById(employeeId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.employeeInfo = response.data.employees;
          this.editForm.patchValue(this.employeeInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.employeeInfo.id == 0) ? "New Employee" : "Editing : " + this.employeeInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.employeeInfo, this.editForm.value);
      this.EmployeeCategoryService.update(this.employeeInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.employeeInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['/employee-category']);

          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }

}
