<app-product-menu> </app-product-menu>

<div class="stock-component-container page-root-container">
        <div class="page-header">
                <div class="page-title">
                        <img class="icon" src="assets/images/menu/svg/stock-item.svg"><span class="title">Stock Items</span>
                </div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                        (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
                        [disabled]="IsDownloading">
                        <img class="download-icon"
                                [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
                        Export
                  </button>
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter #searchFilter [pagingData]="pagingData" 
                [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
        </app-search-filter>
                @if(pagingData.length>0){
                @if(!isHandset){
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="stockList" (matSortChange)="sortData($event)">
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="stock_items.code" mat-header-cell style="width: 80px;"
                                                *matHeaderCellDef>Code</th>
                                        <td mat-cell *matCellDef="let stock" style="width: 80px;"> {{stock.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="stock_items.name" mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let stock"> {{stock.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="stock_item_categories.name">
                                        <th mat-sort-header="stock_item_categories.name" mat-header-cell *matHeaderCellDef>
                                                Category </th>
                                        <td mat-cell *matCellDef="let stock"> {{stock.category_name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="uom_code">
                                        <th mat-sort-header="stock_items.uom_id" mat-header-cell *matHeaderCellDef> UOM </th>
                                        <td mat-cell *matCellDef="let stock"> {{stock.uom_code}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="is_sellable">
                                        <th mat-sort-header="is_sellable" mat-header-cell *matHeaderCellDef> Sellable
                                        </th>
                                        <td mat-cell *matCellDef="let stock">
                                                <ng-container *ngIf="stock.is_sellable === 1; else showNo">
                                                        Yes
                                                </ng-container>
                                                <ng-template #showNo>

                                                </ng-template>
                                        </td>
                                </ng-container>


                                <ng-container matColumnDef="is_manufactured">
                                        <th mat-sort-header="is_manufactured" mat-header-cell *matHeaderCellDef>
                                                Prepared </th>
                                        <td mat-cell *matCellDef="let stock">
                                                <ng-container *ngIf="stock.is_manufactured === 1; else showNo">
                                                        Yes
                                                </ng-container>
                                                <ng-template #showNo>

                                                </ng-template>
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>
                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>

                                                </mat-menu>
                                        </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let stock; columns: displayedColumns;"
                                        (click)="onRowSelected(stock)" (dblclick)="onEdit(stock)"
                                        [ngClass]=" {'row-selected' : stock.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>

                }
                }@else{
                <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
                </div>
                }
        </div>

</div>
<!-- <app-stocks-summary [stock]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-stocks-summary> -->