<div class="util-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/regions" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/syn-error.svg">
                        <span class="master-title" style="word-wrap: break-word;"> Sync Errors

                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/department" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/publish-data.svg">
                        <span class="master-title"> Publish Data</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/payroll" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/sale-item-bulk-update.svg">
                        <span class="master-title">   Sale Items Bulk Update
                        </span>
                    </div>
                </mat-list-item>
          
         
           
         
         
            </div>
        </div>
    </div>
</div>
